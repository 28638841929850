import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './scss/main.scss'
import '../node_modules/bootstrap/dist/js/bootstrap.min'
import axios from "axios";

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'https://dev.upchelas.com'
}

createApp(App).use(store).use(router).mount('#app')
