<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12 desc-wrapper">
          <a href="/">
            <img class="logo scale-with-grid" src="@/assets/images/logo.png" alt="UPChelas logotipo">
          </a>
          <span class="desc">A Unidade Pastoral de Chelas é orientada pelos Frades Menores Conventuais que tentam viver o Evangelho ao jeito de s. Francisco de Assis: “A Regra e a Vida dos Irmãos Menores é esta: observar o santo Evangelho de nosso Senhor Jesus Cristo, vivendo em obediência, sem nada próprio e em castidade”.</span>
        </div>
        <!--        <div class="col-md-4 links">-->
        <!--          <ul>-->
        <!--            <li>-->
        <!--              <router-link-->
        <!--                  :to="{'name': 'contacts'}"-->
        <!--              >-->
        <!--                Contactos e Redes Sociais-->
        <!--              </router-link>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <router-link-->
        <!--                  :to="{'name': 'pastoralUnit'}"-->
        <!--              >-->
        <!--                Conhecer os Freis-->
        <!--              </router-link>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </div>-->
      </div>
      <div class="row">
        <div class="text-center">
          <a href='https://play.google.com/store/apps/details?id=com.upchelas&hl=pt_BR&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
              alt='Disponível no Google Play'
              style="max-width: 150px;"
              src='https://play.google.com/intl/en_us/badges/static/images/badges/pt_badge_web_generic.png'/></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.logo {
  width: 100px;
}

.desc-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }
}

footer {
  background-color: #9FA8DA;
  color: black;
  padding-top: 3em;
  padding-bottom: 3em;
}


.links {
  @media (max-width: 767.98px) {
    margin-top: 1em;
    text-align: center;
  }

  .title {
    font-size: 34px;
    font-weight: 400;
    margin-bottom: 1.5rem;

    @media (max-width: 767.98px) {
      margin-bottom: 1rem;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;

    li {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: white;

      @media (max-width: 767.98px) {
        margin-bottom: 0.75rem;
      }

      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
}

.desc {
  font-size: 18px;
  display: block;
  margin-left: 1rem;

  @media (max-width: 767.98px) {
    max-width: 100%;
    font-size: 16px;
  }
}
</style>
