<template>
  <lightgallery
      :settings="{ speed: 500, plugins: plugins }"
      :onInit="onInit"
      ref="innerGallery"
  >    <a v-show="false" :href="require('@/assets/images/gallery/0.jpg')">
  </a>
      <a v-show="false" :href="require('@/assets/images/gallery/1.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/2.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/3.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/4.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/5.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/6.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/7.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/8.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/9.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/10.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/11.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/12.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/13.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/14.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/15.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/16.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/17.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/18.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/19.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/20.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/21.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/22.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/23.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/24.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/25.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/26.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/27.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/28.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/29.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/30.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/31.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/32.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/33.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/34.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/35.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/36.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/37.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/38.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/39.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/40.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/41.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/42.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/43.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/44.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/45.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/46.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/47.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/48.jpg')">
      </a>
      <a v-show="false" :href="require('@/assets/images/gallery/49.jpg')">
      </a>
  </lightgallery>
</template>

<script>
import Lightgallery from 'lightgallery/vue';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

let instance = null
export default {
  components: {
    Lightgallery,
  },
  data: () => ({
    plugins: [lgZoom],
  }),
  methods: {
    start: () => {
      instance.openGallery()
    },
    onInit: (detail) => {
      instance = detail.instance
    },
  },
}
</script>
