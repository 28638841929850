import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/unidade-pastoral',
        name: 'pastoralUnit',
        component: function () {
            return import('../views/PastoralUnit.vue')
        }
    },
    {
        path: '/caridade',
        name: 'caridade',
        component: function () {
            return import('../views/charity/caridade.vue')
        }
    },
    {
        path: '/caridade/missionarios',
        name: 'missionarios',
        component: function () {
            return import('../views/charity/missionarios.vue')
        }
    },
    {
        path: '/caridade/voluntarios',
        name: 'voluntarios',
        component: function () {
            return import('../views/charity/voluntarios.vue')
        }
    },
    {
        path: '/caridade/centro-santa-beatriz',
        name: 'saintBeaCenter',
        component: function () {
            return import('../views/charity/SaintBeaCenter.vue')
        }
    },
    {
        path: '/caridade/centro-sao-maximiliano-kolbe',
        name: 'saintMaxCenter',
        component: function () {
            return import('../views/charity/SaintMaxCenter.vue')
        }
    },
    {
        path: '/sacramentos/batismo',
        name: 'baptism',
        component: function () {
            return import('../views/sacraments/Baptism.vue')
        }
    },
    {
        path: '/sacramentos/matrimonio',
        name: 'marriage',
        component: function () {
            return import('../views/sacraments/Marriage.vue')
        }
    },
    {
        path: '/vida-pastoral/catequese',
        name: 'catechism',
        component: function () {
            return import('../views/pastoralLife/Catechism.vue')
        }
    },
    {
        path: '/vida-pastoral/focus-ecologico',
        name: 'ecological',
        component: function () {
            return import('../views/pastoralLife/Ecological.vue')
        }
    },
    {
        path: '/vida-pastoral/ofs',
        name: 'ofs',
        component: function () {
            return import('../views/pastoralLife/Ofs.vue')
        }
    },
    {
        path: '/vida-pastoral/jovens',
        name: 'young',
        component: function () {
            return import('../views/pastoralLife/Young.vue')
        }
    },
    {
        path: '/vida-pastoral/escuteiros',
        name: 'scouts',
        component: function () {
            return import('../views/pastoralLife/Scouts.vue')
        }
    },
    {
        path: '/vida-pastoral/familia',
        name: 'family',
        component: function () {
            return import('../views/pastoralLife/Family.vue')
        }
    },
    {
        path: '/conchas',
        name: 'conchas',
        component: function () {
            return import('../views/Conchas.vue')
        }
    },
    {
        path: '/conchas/unsubscribe',
        name: 'conchas-unsubscribe',
        component: function () {
            return import('../views/ConchasUnsubscribe.vue')
        }
    },
    {
        path: '/contactos',
        name: 'contacts',
        component: function () {
            return import('../views/Contacts.vue')
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
