<template>
<header>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div>
        <router-link
            :to="{'name': 'home'}"
            class="navbar-brand"
        >
          <img class="logo" src="@/assets/images/logo.png" alt="UPChelas logotipo">
          <span class="mobile-title">UPChelas</span>
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" :class="{'show': show}" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link
                  :to="{'name': 'home'}"
                  class="nav-link"
                  :class="{'active': $route.name === 'home'}"
              >
                Início
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                  :to="{'name': 'pastoralUnit'}"
                  class="nav-link"
                  :class="{'active': $route.name === 'pastoralUnit'}"
              >
                Unidade Pastoral
              </router-link>
            </li>
              <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" ref="sacraments" :class="{'active': ['saintBeaCenter', 'saintMaxCenter'].includes($route.name)}" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Caridade e Evangelização</a>
              <div class="dropdown-menu">
                <router-link
                    :to="{'name': 'caridade'}"
                    class="dropdown-item"
                    @click="$refs.charity.click()"
                >
                  Grupo Paroquial Da Caridade
                </router-link>
                <router-link
                    :to="{'name': 'saintBeaCenter'}"
                    class="dropdown-item"
                    @click="$refs.charity.click()"
                >
                  Centro Social e Cultural de Santa Beatriz
                </router-link>
                <router-link
                    :to="{'name': 'saintMaxCenter'}"
                    class="dropdown-item"
                    @click="$refs.charity.click()"
                >
                  Centro Social Paroquial São Maximiliano de Kolbe
                </router-link>
                <router-link
                    :to="{'name': 'missionarios'}"
                    class="dropdown-item"
                    @click="$refs.charity.click()"
                >
                  Missionários
                </router-link>
                <router-link
                    :to="{'name': 'voluntarios'}"
                    class="dropdown-item"
                    @click="$refs.charity.click()"
                >
                  Voluntários
                </router-link>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" ref="sacraments" :class="{'active': ['baptism', 'marriage'].includes($route.name)}" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Sacramentos</a>
              <div class="dropdown-menu">
                <router-link
                    :to="{'name': 'baptism'}"
                    class="dropdown-item"
                    @click="$refs.sacraments.click()"
                >
                  Batismo
                </router-link>
                <router-link
                    :to="{'name': 'marriage'}"
                    class="dropdown-item"
                    @click="$refs.sacraments.click()"
                >
                  Matrimónio
                </router-link>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" ref="sacraments" :class="{'active': ['catechism', 'ecological', 'ofs', 'pilgrims', 'scouts'].includes($route.name)}" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Vida Pastoral</a>
              <div class="dropdown-menu">
                <router-link
                    :to="{'name': 'catechism'}"
                    class="dropdown-item"
                    @click="$refs.sacraments.click()"
                >
                  Catequese
                </router-link>
                <router-link
                    :to="{'name': 'ofs'}"
                    class="dropdown-item"
                    @click="$refs.sacraments.click()"
                >
                  OFS
                </router-link>
                <router-link
                    :to="{'name': 'scouts'}"
                    class="dropdown-item"
                    @click="$refs.sacraments.click()"
                >
                  Escuteiros
                </router-link>
                <router-link
                    :to="{'name': 'young'}"
                    class="dropdown-item"
                    @click="$refs.sacraments.click()"
                >
                  Jovens
                </router-link>
                <router-link
                    :to="{'name': 'family'}"
                    class="dropdown-item"
                    @click="$refs.sacraments.click()"
                >
                  Família
                </router-link>
                <router-link
                    :to="{'name': 'ecological'}"
                    class="dropdown-item"
                    @click="$refs.sacraments.click()"
                >
                  Focus Ecológico
                </router-link>
              </div>
            </li>
            <li class="nav-item">
              <router-link
                  :to="{'name': 'conchas'}"
                  class="nav-link"
                  :class="{'active': $route.name === 'conchas'}"
              >
                Conchas
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  :to="{'name': 'contacts'}"
                  class="nav-link"
                  :class="{'active': $route.name === 'contacts'}"
              >
                Contactos
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>
</template>

<style scoped lang="scss">
.logo {
  width: 100px;

  @media (max-width: 767.98px) {
    width: 50px;
  }
}

.mobile-title {
  display: none;

  @media (max-width: 767.98px) {
    display: inline;
    font-weight: 700;
    font-size: 22px;
    margin-left: 0.5rem;
  }
}

.navbar {
  font-size: 18px;

  > div {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .nav-link.active {
    background-color: unset !important;
    color: #402F16;
    border-bottom: 3px solid #402F16;
    border-radius: 0;
    font-weight: 600;
  }
}
</style>
<script setup>
</script>
